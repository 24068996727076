@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Black.eot');
    src: url('Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Black.woff2') format('woff2'),
        url('Poppins-Black.woff') format('woff'),
        url('Poppins-Black.ttf') format('truetype'),
        url('Poppins-Black.svg#Poppins-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Bold.eot');
    src: url('Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Bold.woff2') format('woff2'),
        url('Poppins-Bold.woff') format('woff'),
        url('Poppins-Bold.ttf') format('truetype'),
        url('Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-BoldItalic.eot');
    src: url('Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-BoldItalic.woff2') format('woff2'),
        url('Poppins-BoldItalic.woff') format('woff'),
        url('Poppins-BoldItalic.ttf') format('truetype'),
        url('Poppins-BoldItalic.svg#Poppins-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-BlackItalic.eot');
    src: url('Poppins-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-BlackItalic.woff2') format('woff2'),
        url('Poppins-BlackItalic.woff') format('woff'),
        url('Poppins-BlackItalic.ttf') format('truetype'),
        url('Poppins-BlackItalic.svg#Poppins-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ExtraLight.eot');
    src: url('Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ExtraLight.woff2') format('woff2'),
        url('Poppins-ExtraLight.woff') format('woff'),
        url('Poppins-ExtraLight.ttf') format('truetype'),
        url('Poppins-ExtraLight.svg#Poppins-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ExtraBold.eot');
    src: url('Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ExtraBold.woff2') format('woff2'),
        url('Poppins-ExtraBold.woff') format('woff'),
        url('Poppins-ExtraBold.ttf') format('truetype'),
        url('Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ExtraBoldItalic.eot');
    src: url('Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('Poppins-ExtraBoldItalic.woff') format('woff'),
        url('Poppins-ExtraBoldItalic.ttf') format('truetype'),
        url('Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ExtraLightItalic.eot');
    src: url('Poppins-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('Poppins-ExtraLightItalic.woff') format('woff'),
        url('Poppins-ExtraLightItalic.ttf') format('truetype'),
        url('Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-LightItalic.eot');
    src: url('Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-LightItalic.woff2') format('woff2'),
        url('Poppins-LightItalic.woff') format('woff'),
        url('Poppins-LightItalic.ttf') format('truetype'),
        url('Poppins-LightItalic.svg#Poppins-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Light.eot');
    src: url('Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Light.woff2') format('woff2'),
        url('Poppins-Light.woff') format('woff'),
        url('Poppins-Light.ttf') format('truetype'),
        url('Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Medium.eot');
    src: url('Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Medium.woff2') format('woff2'),
        url('Poppins-Medium.woff') format('woff'),
        url('Poppins-Medium.ttf') format('truetype'),
        url('Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Italic.eot');
    src: url('Poppins-Italic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Italic.woff2') format('woff2'),
        url('Poppins-Italic.woff') format('woff'),
        url('Poppins-Italic.ttf') format('truetype'),
        url('Poppins-Italic.svg#Poppins-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-MediumItalic.eot');
    src: url('Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-MediumItalic.woff2') format('woff2'),
        url('Poppins-MediumItalic.woff') format('woff'),
        url('Poppins-MediumItalic.ttf') format('truetype'),
        url('Poppins-MediumItalic.svg#Poppins-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-SemiBoldItalic.eot');
    src: url('Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('Poppins-SemiBoldItalic.woff') format('woff'),
        url('Poppins-SemiBoldItalic.ttf') format('truetype'),
        url('Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-SemiBold.eot');
    src: url('Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-SemiBold.woff2') format('woff2'),
        url('Poppins-SemiBold.woff') format('woff'),
        url('Poppins-SemiBold.ttf') format('truetype'),
        url('Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Regular.eot');
    src: url('Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Regular.woff2') format('woff2'),
        url('Poppins-Regular.woff') format('woff'),
        url('Poppins-Regular.ttf') format('truetype'),
        url('Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Thin.eot');
    src: url('Poppins-Thin.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Thin.woff2') format('woff2'),
        url('Poppins-Thin.woff') format('woff'),
        url('Poppins-Thin.ttf') format('truetype'),
        url('Poppins-Thin.svg#Poppins-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ThinItalic.eot');
    src: url('Poppins-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ThinItalic.woff2') format('woff2'),
        url('Poppins-ThinItalic.woff') format('woff'),
        url('Poppins-ThinItalic.ttf') format('truetype'),
        url('Poppins-ThinItalic.svg#Poppins-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Merriweather';
    src: url('Merriweather-Black.eot');
    src: url('Merriweather-Black.eot?#iefix') format('embedded-opentype'),
        url('Merriweather-Black.woff2') format('woff2'),
        url('Merriweather-Black.woff') format('woff'),
        url('Merriweather-Black.ttf') format('truetype'),
        url('Merriweather-Black.svg#Merriweather-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('Merriweather-BoldItalic.eot');
    src: url('Merriweather-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Merriweather-BoldItalic.woff2') format('woff2'),
        url('Merriweather-BoldItalic.woff') format('woff'),
        url('Merriweather-BoldItalic.ttf') format('truetype'),
        url('Merriweather-BoldItalic.svg#Merriweather-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('Merriweather-Italic.eot');
    src: url('Merriweather-Italic.eot?#iefix') format('embedded-opentype'),
        url('Merriweather-Italic.woff2') format('woff2'),
        url('Merriweather-Italic.woff') format('woff'),
        url('Merriweather-Italic.ttf') format('truetype'),
        url('Merriweather-Italic.svg#Merriweather-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('Merriweather-Bold.eot');
    src: url('Merriweather-Bold.eot?#iefix') format('embedded-opentype'),
        url('Merriweather-Bold.woff2') format('woff2'),
        url('Merriweather-Bold.woff') format('woff'),
        url('Merriweather-Bold.ttf') format('truetype'),
        url('Merriweather-Bold.svg#Merriweather-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('Merriweather-BlackItalic.eot');
    src: url('Merriweather-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Merriweather-BlackItalic.woff2') format('woff2'),
        url('Merriweather-BlackItalic.woff') format('woff'),
        url('Merriweather-BlackItalic.ttf') format('truetype'),
        url('Merriweather-BlackItalic.svg#Merriweather-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('Merriweather-Light.eot');
    src: url('Merriweather-Light.eot?#iefix') format('embedded-opentype'),
        url('Merriweather-Light.woff2') format('woff2'),
        url('Merriweather-Light.woff') format('woff'),
        url('Merriweather-Light.ttf') format('truetype'),
        url('Merriweather-Light.svg#Merriweather-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('Merriweather-LightItalic.eot');
    src: url('Merriweather-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Merriweather-LightItalic.woff2') format('woff2'),
        url('Merriweather-LightItalic.woff') format('woff'),
        url('Merriweather-LightItalic.ttf') format('truetype'),
        url('Merriweather-LightItalic.svg#Merriweather-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('Merriweather-Regular.eot');
    src: url('Merriweather-Regular.eot?#iefix') format('embedded-opentype'),
        url('Merriweather-Regular.woff2') format('woff2'),
        url('Merriweather-Regular.woff') format('woff'),
        url('Merriweather-Regular.ttf') format('truetype'),
        url('Merriweather-Regular.svg#Merriweather-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

