@import url(../Assets/font/stylesheet.css);
@import url(./custom.css);
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-y: hidden; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

.blog-header .row {
  position: relative;
  z-index: 9;
}

.blog-header {
  box-shadow: 0 0 8px 0px #00000082;
  background-color: #526574;
  position: fixed;
}
.blog-header-error {
  position: relative;
}
.blog-header-error .back {
  margin-left: 20px;
}
.blog-header-error .call {
  color: #fff;
  margin-right: 20px;
  font-size: 14px;
  font-weight: normal;
}

.blog-header::before {
  background: url("../Assets/img/header_bg.png");
  background-repeat: repeat-x;
  background-size: 40%;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0px;
  opacity: 0.2;
}

.header-logo img {
  width: auto;
  height: 45px;
}

.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  outline: none;
}
.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-light {
  color: #fff;
  border-color: transparent;
}
.btn-light:hover {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.btn-light:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.back {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.back img,
.back-button img {
  width: 15px;
  height: 15px;
  fill: #fff;
  margin-right: 10px;
}
.call {
  color: #273969;
  font-size: 16px;
  font-weight: bold;
}
.cwlo-btn-pt {
  margin-top: 40px !important;
  text-align: center;
  justify-content: space-around;
  display: flex;
}
.back:hover {
  color: #fff;
}
.ques-bg {
  background: #273969;
  padding: 50px;
  /* height: 100vh; */
  position: relative;
}
.ques-section {
  padding: 80px 0;
}
.que-section {
  padding: 0px 0;
}
.que-section p {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.que-section p span {
  font-size: 32px;
}
.que-bg {
  background: #fff;
  min-width: 60%;
  padding: 15px 50px;
  margin: 0 auto;
  min-height: 250px;
  border-radius: 5px;
  /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.4); */
}
.que-bg hr {
  margin: 10px 0;
}
.que-title {
  color: #526574;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  text-transform: capitalize;
}
.ques-title {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-bottom: 25px;
}
.que-list {
  padding: 15px 0 15px 0;
  margin: 0;
}
.que-list li {
  list-style: none;
  display: inline-flex;
  margin: 0;
  padding: 0px 0;
  color: #283a6a;
  font-size: 14px;
  word-break: normal;
}
.planselectedicon {
  position: absolute;
  right: 12px;
}

.que-list li .fa-arrow-circle-o-right {
  padding-right: 10px;
  color: #fff !important;
  font-size: 16px;
}
.que-option-tow {
  display: flex !important;
  flex-wrap: wrap !important;
  /* justify-content: space-between !important; */
}

.que-option {
  padding-top: 20px;
  padding-bottom: 10px;
}
.que-option .form-check-label {
  color: #273969;
  align-self: center;
  cursor: pointer;
  padding: 4px;
  margin: 0;
}
.que-option .form-check-input[type="radio"]:checked::before {
  background: #f8f6fc;
  content: "";
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -3px;
  left: -3px;
}
.que-option .form-check-input[type="radio"]:checked::after {
  background: #367f84;
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  position: relative;
  top: -30px;
  left: 0px;
  cursor: pointer;
}
.que-option .form-check-input[type="radio"]::before {
  background: #ffffff;
  border: 1px solid #ddd;
  content: "";
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -3px;
  left: -3px;
  cursor: pointer;
}
/*  */

.que-option .form-check-input[type="checkbox"]:checked::after {
  background: #367f84;
  content: "\2714";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  position: relative;
  top: -31px;
  left: 0px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  text-align: center;
}
.que-option .form-check-input[type="checkbox"]::before {
  background: #ffffff;
  border: 1px solid #ddd;
  content: "";
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  top: -1px;
  left: -1px;
  cursor: pointer;
}
.custom-checkbox-checked:before {
  content: "\2714";
  position: absolute;
  left: 0;
  top: 10px;
  width: 18px !important;
  height: 18px !important;
  background: #8860d0 !important;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
}
.form-check-input {
  position: absolute;
  margin-left: -1.25rem;
  width: 18px;
  height: 18px;
  margin-top: 0.3rem !important;
}

.purchase-section .form-check-input {
  position: inherit;
  margin-left: -1.25rem;
  width: 18px;
  height: 18px;
  margin-top: 0.2rem !important;
  margin-right: 8px;
}

.form-check-input:focus {
  box-shadow: none;
}
.small-tile {
  color: #8860d0 !important;
  font-size: 12px !important;
  text-align: center;
}
.que-option .box-flex {
  display: flex;
  align-items: center;
}
.que-option input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  font-size: 24px;
  width: 45px;
}

.que-option input[type="number"]::-webkit-inner-spin-button,
.que-option input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.que-option .number-input {
  display: inline-flex;
  align-items: center;
}
.que-option .number-input * {
  border: 1px solid #fff;
  background: #f8f5ff;
  color: #000;
}
.que-option .number-input,
.que-option .number-input * {
  box-sizing: border-box;
  margin: 0 13px;
  height: 45px;
  /*width: 50px;*/
  border-radius: 5px;
  text-align: center;
}

.que-option .number-input.category-pkg-qty {
  width: 40px;
  display: flex;
  flex-direction: column;
  margin: 5px 15px 60px 0px;
}

.que-option .number-input.category-pkg-qty button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
}

.que-option .category-pkg-qty input[type="number"] {
  font-size: 16px;
  width: 32px;
  margin: 8px 0;
}

.que-option .number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 0;
  border-radius: 5px;
  position: relative;
}
.custom-button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #8860d0;
  color: #8860d0;
}
.custom-button:hover {
  -webkit-appearance: none;
  background-color: #8860d0;
  border: 1px solid #8860d0;
}
.que-option .form-control,
.form-control:focus {
  box-shadow: none;
  border-color: #8860d0;
  color: #8860d0;
  background: #f8f5ff;
}
.que-option .form-control::placeholder {
  color: #8860d0 !important;
  font-family: "Merriweather", serif !important;
}
input::placeholder {
  color: #000000 !important;
  font-family: "Merriweather", serif !important;
}
.postque-bg .que-data input::placeholder {
  color: #ccc !important;
}
.small-tital {
  color: #273969 !important;
  font-size: 14px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.slide-section {
  text-align: center;
  margin: 0 auto;
}
.slide-section ul {
  padding: 30px 0 0 0;
  margin: 0;
  justify-content: space-between;
  display: flex;
}
.slide-section ul li:nth-child(1) {
  padding-left: 0;
}
.slide-section ul li:nth-child(3) {
  padding-right: 0;
}

.slide-section ul li {
  padding: 3px 5px;
  margin: 0;
  list-style: none;
  display: inline-block;
  cursor: pointer;
}
.slide-section ul li i {
  color: #fff;
  font-size: 26px;
}

.slide-arrows ul li {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.slide-arrows ul li svg {
  margin: 0px 10px 0px 10px;
}

.que-top-pre p {
  text-align: center !important;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.progress {
  background-color: transparent;
  border: 1px solid #fff;
  margin-top: 50px;
  height: 15px;
}
.progress-bar {
  background-color: #fff;
}
.answered {
  color: #ddd;
  font-size: 14px;
  text-align: right;
}
.psw-btn {
  background: #273969 !important;
  margin-bottom: 30px;
  color: #fff !important;
  border: 1px solid #273969 !important;
}
.psw-btn:hover {
  color: #273969;
  background: transparent;
}
.pswa-btn {
  background: #9c27b0 !important;
  margin-bottom: 30px;
  color: #fff !important;
  border: 1px solid #9c27b0;
}
.pswa-btn:hover {
  color: #9c27b0 !important;
  background: #fff !important;
}
.profile-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 4px solid #fff;
  background-color: transparent;
  object-fit: contain;
}
.logout-btn {
  display: flex;
  align-items: center;
}
.lgt-img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-right: 5px;
  object-fit: contain;
}
.btn-lgt {
  background: transparent;
  font-size: 12px;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.postque .que-section {
  padding-top: 82px;
}
/* .postque .postque-bg {
  background: #367f84;
  padding: 50px;
  /* height: 100%; 
  min-height: 100vh;
  position: relative;
} */
.postque .postque-title {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  text-align: justify;
  margin-bottom: 15px;
  width: 100%;
  text-transform: capitalize;
}

.postque .custom-radio-btn {
  border: 1px solid #fff;
  color: #fff;
  margin-left: 0px;
}

.postque .custom-radio-btn span {
  color: #fff;
}
.postque .ans-fill {
  display: inline-grid;
  padding: 50px 0 50px 0;
}
.postque .ans-fill i {
  padding: 0 5px 0 0px;
  position: relative;
  top: 5px;
  font-size: 20px;
  font-weight: normal;
  color: #fff;
}
.postque .ans-fill input {
  background: transparent;
  border: 0;
  color: #fff;
  border-bottom: 1px solid #c9cdd9;
  border-radius: 0;
  width: 100%;
}
.postque .next-section {
  position: absolute;
  left: 15px;
  top: 390px;
  transform: rotate(270deg);
  transform-origin: 0 0;
}
.postque .next-section ul {
  margin: 0;
  padding: 0;
}
.postque .next-section ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  opacity: 0.5;
}
.postque .next-section ul li:nth-child(1) {
  padding: 0 30px 0 0;
}
.postque .next-section ul li i {
  padding-right: 5px;
  padding-left: 5px;
}
.postque .next-section ul li a {
  color: #fff;
}

.postque .postquesect-title {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  margin-bottom: 30px;
}

.postque .postque-desc {
  color: #fff;
  font-size: 13px;
  text-align: left;
  margin-bottom: 30px;
}
.postque .postque-desc span {
  font-size: 13px;
  font-style: italic;
}
.postque .postquesect-title.shadow {
  display: block;
  padding: 20px 15px;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
}
.postque .list-que:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.35);
  /* height: 90vh; */
  z-index: -1;
}

.postque .list-que ul {
  margin: 0;
  padding: 0px 0px 0px 0px;
  position: inherit;
  z-index: 9;
}

.postque .list-que ul li span {
  padding-left: 20px;
}
.postque .list-que ul li i,
.postque .list-que ul li svg {
  padding-right: 5px;
}
.postque .list-que ul li span.shadow {
  display: block;
  padding: 18px 15px;
  font-size: 20px;
  font-weight: 600;
}
.postque .list-que {
  overflow-y: auto;
  background-size: cover;
  position: relative;
  background-position: center;
  /* height: 90vh; */
  bottom: 0;
  z-index: 0;
}
.postque .list-que ul li {
  padding: 8px 0;
  margin: 0;
  list-style: none;
  font-size: 16px;
  display: block;
  cursor: pointer;
}
.attent {
  /* font-weight: bold; */
  font-size: 16px;
  text-transform: capitalize;
}
li .shadow {
  background: rgba(94, 113, 106, 0.5) !important;
  box-shadow: 0 0.5rem 1rem rgba(255, 0, 0, 0.15) !important;
}
.reviewsection_title {
  font-weight: bold;
  color: #145da0;
  text-transform: capitalize;
}
.reviewsection_quetitle {
  font-weight: 600 !important;
  background-color: rgba(216, 179, 79, 0.16);
  color: #333333;
  font-size: 15px;
  letter-spacing: 0.4px;
}
.reviewsection_que {
  font-weight: 600;
  color: #0c2d48;
  font-size: 15px;
  letter-spacing: 0.4px;
  text-align: justify;
}
.payment-section {
  padding-bottom: 40px;
  height: 90vh;
  overflow-y: auto;
}
.scrollable-section:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: -1;
}
.paymentmain_section:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: -1;
}
.homesection_main::after {
  content: "";
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: -1;
  height: 95vh;
}
.scrollable-section {
  margin-top: 60px;
  /* max-height: 86vh; */
  /* overflow-y: auto; */
}
.pay-main-tital {
  color: #ffffff;
  /* margin-top: 15px; */
  font-weight: 600;
}
.pay-sub-tital {
  color: #b5b5b5;
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
}
.payment-note {
  background: rgba(94, 113, 106, 0.5);
  border: 1px solid rgba(94, 113, 106, 0.5);
  border-radius: 5px;
}
.payment-note p {
  font-size: 14px;
  color: #fff;
  margin: 0;
  padding: 15px;
}
.select-payment {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.select-payment .pcard {
  width: 200px;
}
.payment-method {
  background: rgba(94, 113, 106, 0.5);
  border: 1px solid rgba(94, 113, 106, 0.5);
  border-radius: 5px;
  margin: 30px 0;
  padding: 30px 15px;
}
.bg-white {
  /*background: #fff;*/
}
.payment-method h3,
.order-section h3 {
  color: #fff;
  margin: 0px 0 20px 0;
}
.payment-method input.custom-input,
.discount input.custom-input {
  height: 45px;
  background: rgba(94, 113, 106, 0.5);
  border: 1px solid rgba(94, 113, 106, 0.5);
  color: #fff;
}
.discount input.custom-input:focus {
  background: rgba(94, 113, 106, 0.5);
  border: 1px solid rgba(94, 113, 106, 0.5);
  color: #fff;
}
.payment-method .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked,
.payment-method .MuiFormControlLabel-label,
.payment-method form input::placeholder,
.discount input.custom-input::placeholder,
.payment-method .MuiFormLabel-root,
.payment-method .MuiInputBase-root,
.discount .MuiFormLabel-root,
.discount .MuiInputBase-root {
  color: #fff !important;
}
.payment-method .MuiOutlinedInput-notchedOutline,
.payment-method .MuiOutlinedInput-root:hover,
.discount .MuiOutlinedInput-notchedOutline,
.discount .MuiOutlinedInput-root:hover,
.discount input::placeholder {
  border-color: #fff !important;
}
.order-section {
  background: rgba(94, 113, 106, 0.5);
  margin: 30px 0 20px 0;
  padding: 30px 30px 12px 30px;
}
.order-total ul {
  margin: 0;
  padding: 0;
}
.order-total ul li {
  margin: 0;
  padding: 20px 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
}
.order-total ul li:first-child {
  border-top: 1px solid #fff;
}
.order-total ul li:last-child {
  border-bottom: 0;
}
.total-label,
.total-price {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.discount {
  /* display: flex; */
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.btn-apply {
  background-color: #d8b34f;
  padding: 11px 35px;
  /* margin-left: 15px; */
  border: 0;
  border-radius: 5px;
  color: #fff;
}
.btn-resend {
  border-radius: 30px !important;
  background-color: #d8b34f !important;
  border-color: #d8b34f !important;
  color: #fff !important;
  margin: 10px !important;
  padding: 10px 30px !important;
}
.purchase-section .form-check input.form-check-input {
  float: none;
  width: 18px;
  height: 18px;
}
.purchase-section .form-check .form-check-label {
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}
.btn-purchase {
  background: #d8b34f;
  padding: 11px 35px;
  margin: 30px 0;
  font-weight: 500;
  letter-spacing: 1px;
  border: 0;
  border-radius: 5px;
  color: #fff;
}
.btn-purchase:disabled,
.btn-purchase[disabled] {
  background-color: #e9b975;
  color: #fff;
}
.purchase-section {
  margin: 30px 0 0 0;
}
.payment-op1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  padding: 10px 15px;
  /* margin-top: 30px; */
}
.purple {
  color: #387bff;
}
.payment-op1 h3 {
  font-size: 18px;
  color: #fff;
}
.payment-op1 h6 {
  font-size: 14px;
  color: #273969;
}
.amount-note {
  background: #f9f9f9;
  border: 1px solid #f9f9f9;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px 15px 0 15px;
}
.amount-note p {
}
.quote {
  /* background: #f9f9f9; */
  background: rgba(94, 113, 106, 0.5) !important;
  border: 1px solid rgba(94, 113, 106, 0.5);
  border-radius: 5px;
  padding: 15px 15px 15px 15px;
  max-width: 200px;
  max-height: 250px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.quote h6 {
  font-size: 16px;
  color: #273969;
}
.quote p {
  font-size: 30px;
  font-weight: bold;
  color: #273969;
}
.quote a {
  color: #9c27b0;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  letter-spacing: 1px;
  border: 1px solid;
  padding: 5px 10px;
  background: #fff;
  border-radius: 3px;
  text-transform: uppercase;
}
.quote a:hover {
  text-decoration: none;
  color: #273969;
}
.thanks-section {
  max-width: 650px;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
}
.thanks-section h2 {
  color: #ffffff;
  font-weight: 600;
}
.thank-box {
  /* background: #f9f9f9; */
  background: #367f84;
  border: 1px solid #367f84;
  border-radius: 5px;
  padding: 15px 15px 15px 15px;
  margin-top: 30px;
}
.thank-box p {
  /* color: #9c27b0; */
  color: #ffffff;
  font-weight: 500;
}

.MuiFormHelperText-contained {
  margin: 0px 0px !important;
}

.badge {
  /* background-color: #e1f3f5; */
  border-radius: 50%;
  /* color: #22696d; */
  color: #ffffff;
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: normal;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
}

.stepper {
  margin: 0;
  padding: 0;
  border-radius: 6px;
  /* border: 1px solid black; */
  display: inline-flex;
  flex-direction: row;
  position: relative;
  list-style: none;
  height: 100px;
  /* overflow: hidden; */
}
.stepper__wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.stepper__item:first-child .stepper__link {
  padding-left: 20px;
}
.stepper__item:last-child .stepper__link {
  padding-right: 20px;
}
.stepper__link {
  background: #367f84;
  color: #fff;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none !important;
  padding: 22px 0 22px 48px;
  position: relative;
}
.stepper__link::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid #367f84;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.stepper__item:last-child .stepper__link::after {
  content: " ";
  display: none;
}

.stepper__item:last-child .stepper__link::before {
  content: " ";
  display: none;
}

.stepper__item:last-child .stepper__link {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.stepper__item:first-child .stepper__link {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.stepper__link::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}
.stepper__icon {
  width: 15%;
  margin-right: 10px;
}

.stepper__link:hover {
  background-color: #367f84;
  color: #fff;
}
.stepper__link:hover::after {
  border-left-color: #367f84;
}
.stepper__link--active,
.stepper__link--active:hover {
  background-color: #22696d;
  color: #fff;
}
.stepper__link--active::after,
.stepper__link--active:hover::after {
  border-left-color: #22696d;
}
.stepper__link--done {
  background-color: #367f84;
  color: #fff;
}
.stepper__link--done::after {
  border-left-color: #367f84;
}
.stepper__link--done .fa-check {
  color: #05c305;
}
.stepper__link--disabled,
.stepper__link--disabled:hover {
  color: #18464a;
  background: #fff;
}
.stepper__link--disabled::after,
.stepper__link--disabled:hover::after {
  border-left-color: #fff;
}
.stepper__link--disabled::before {
  border-left-color: #86b2b5;
}
.stepper-box {
  display: flex;
  align-items: center;
}

.stepper_text {
  text-align: left;
  padding-left: 10px;
  /* font-size: 16px; */
  font-size: 12px;
}

.stepper-btn {
  margin-top: 200px;
  text-align: center;
}
.stepper-btn a {
  padding: 10px 20px;
  background: #d8b34f;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  border-radius: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
}

.stepper__link--disabled .badge {
  background-color: transparent;
  border: 1px solid #18464a;
  color: #18464a;
}

.optscroll {
  padding: 15px;
  height: 200px;
  overflow-y: auto;
}

/*
 *  STYLE 1
 */

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(216, 216, 216);
}

.homenavbutton {
  border-radius: 30px !important;
  background-color: #d8b34f !important;
  color: #fff !important;
  margin: 10px !important;
  padding: 10px 30px !important;
  min-width: 200px !important;
}
.scrollable-section .stepper_main .MuiStepIcon-root.MuiStepIcon-completed,
.payment-section .stepper_main .MuiStepIcon-root.MuiStepIcon-completed {
  color: #e5c896 !important;
}
.scrollable-section .stepper_main .MuiStepIcon-root.MuiStepIcon-active,
.payment-section .stepper_main .MuiStepIcon-root.MuiStepIcon-active {
  color: #d8b34f;
}
.scrollable-section .stepper_main .MuiStepIcon-root,
.payment-section .stepper_main .MuiStepIcon-root {
  color: #f9ead1;
}
.scrollable-section .stepper_main .MuiStepIcon-root .MuiStepIcon-text,
.payment-section .stepper_main .MuiStepIcon-root .MuiStepIcon-text {
  fill: #000;
}
.scrollable-section .stepper_main.MuiPaper-root,
.payment-section .stepper_main.MuiPaper-root {
  background-color: transparent !important;
}
.scrollable-section .stepper_main .MuiStepLabel-label,
.scrollable-section .stepper_main .MuiStepLabel-label.MuiStepLabel-completed,
.scrollable-section .stepper_main .MuiStepLabel-label.MuiStepLabel-active,
.payment-section .stepper_main .MuiStepLabel-label,
.payment-section .stepper_main .MuiStepLabel-label.MuiStepLabel-completed,
.payment-section .stepper_main .MuiStepLabel-label.MuiStepLabel-active {
  color: #fff;
}
.text-white {
  color: #fff !important;
}

.disabled {
  color: #c7c9ce !important;
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
}

.box {
  margin-top: 15px;
}
.postque {
  padding-top: 0px;
}
.sticky {
  position: sticky;
  top: 0;
}
.main-title {
  background: #f8a934;
  color: #fff;
  display: block;
  padding: 10px 10px;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 5px;
  margin-bottom: 25px;
}
.list-group-item {
  border: 0;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.4px;
}
.card-footer {
  display: flex;
  justify-content: space-between;
}

.react-tel-input .form-control:focus {
  background-color: transparent !important;
  box-shadow: unset !important;
  border: 1px solid #cacaca !important;
}
.flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}
.flag-dropdown:focus {
  background-color: transparent !important;
  border: none !important;
}
.selected-flag {
  background-color: transparent !important;
  border: none !important;
}

.selected-flag:focus {
  background-color: transparent !important;
  border: none !important;
}
.que-data .invalid-feedback {
  color: #ffff00;
}
.que-data .MuiInput-underline.Mui-error:after {
  border-bottom-color: #ffff00;
}
.que-data .MuiInput-underline:before {
  border-bottom: 1px solid #fff;
}
.que-data .MuiIconButton-root {
  color: #ffffff;
}
.que-data-cp .invalid-feedback {
  color: #ffff00;
}
.payment-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

input[type="radio"] {
  appearance: none;
}
input[type="radio"] + label {
  cursor: pointer;
  position: relative;
  padding-left: 30px;
}
input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #8860d0;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 0;
  opacity: 1;
  transition: all 0.3s;
}
input[type="radio"] + label::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #8860d0;
  background: #8860d0;
  border-radius: 50%;
  position: absolute;
  left: 3px;
  bottom: 5px;
  opacity: 0;
  transition: all 0.3s;
}
input[type="radio"]:checked + label::after {
  opacity: 1;
}

.btn-primary:hover {
  background-color: #273969;
  border-color: #273969;
}
.btn-primary.focus,
.btn-primary:focus {
  background-color: #273969;
  border-color: #273969;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #273969;
  border-color: #273969;
  box-shadow: unset !important;
}
.form-control {
  font-size: 16px;
}

.que-data-cp {
  padding-bottom: 160px;
}

.toast {
  background: #dff2bf !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.service-price {
  font-weight: 600;
}
.btn-custom {
  color: #fff !important;
  border-color: #fff !important;
  text-transform: capitalize;
}
.btn-custom:hover {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.dynamic-btn {
  text-transform: capitalize;
}
.dynamic-btn.active {
  color: #fff !important;
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}

/*
 *  STYLE 1
 */

/* #style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
} */

/*
  *  STYLE 2
  */

.btn-outline-light {
  color: #f8f9fa !important;
  border-color: #d8b34f !important;
  background: #d8b34f !important;
  text-transform: capitalize !important;
}

.cm-footer-copy p {
  font-size: 12px;
  margin: 0px;
}
.cm-footer-menu ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  list-style: none;
}

.cm-footer-menu ul li a {
  font-size: 12px;
  color: #869099;
  padding: 0px 0px 0px 20px;
  text-decoration: none;
}

.line-pay {
  border-bottom: 1px solid #fff;
}

.plans {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  max-width: 970px;
  /* padding: 85px 50px; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(94, 113, 106, 0.5);
  border-radius: 10px;
  /* -webkit-box-shadow: 0px 8px 10px 0px #d8dfeb; */
  /* box-shadow: 0px 8px 10px 0px #d8dfeb; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 2% 2% 0;
}

.plans .plan input[type="radio"],
.plans .plan input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.plans {
  width: 40%;
}

.my-auto .plans,
.box .plans {
  width: 100%;
}

.plans .plan {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* border: 2px solid #e1e2e7; */
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
  height: 100%;
  align-items: center;
}

.cat-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 970px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(255, 222, 189, 1);
  border-radius: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 2% 2% 0;
}

.cat-description h3 {
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  transition: box-shadow 0.4s;
  position: relative;
  height: 100%;
  align-items: center;
  font-size: 16px;
}
.cat-error h3 {
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  transition: box-shadow 0.4s;
  position: relative;
  height: 100%;
  align-items: center;
  font-size: 16px;
  color: #ffff00;
}

.plans .plan .plan-content img {
  margin-right: 10px;
}

.plans .plan .plan-details span {
  margin-bottom: 0px;
  display: block;
  font-size: 16px;
  color: #252f42;
  /* max-width: 190px; */
}

.plan-details {
  max-width: 200px;
}

.container .title {
  font-size: 16px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #252f42;
  margin-bottom: 20px;
}

.plans .plan .plan-details p {
  color: #646a79;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0 !important;
}

/* .plans .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
} */

.plans .plan .form-check input[type="radio"]:checked + .plan-content:after,
.plans .plan .form-check input[type="checkbox"]:checked + .plan-content:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #216fe0;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #eaf1fe;
  box-shadow: 0px 0px 0px 2px #eaf1fe;
}
.plans .plan input[type="radio"]:checked,
.plans .plan input[type="checkbox"]:checked {
  background: rgba(94, 113, 106, 0.5);
}
.plans .plan input[type="radio"]:checked + .plan-content,
.plans .plan input[type="checkbox"]:checked + .plan-content {
  /* border: 2px solid #eaf1fe; */
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
  align-items: center;
  height: 100%;
}

.plans .plan input[type="radio"]:checked + .plan-content .plan-details span,
.plans .plan input[type="radio"]:checked + .plan-content .plan-details p,
.plans .plan input[type="checkbox"]:checked + .plan-content .plan-details span,
.plans .plan input[type="checkbox"]:checked + .plan-content .plan-details p {
  color: #000 !important;
}
.plans .plan input[type="radio"]:checked + .plan-content .lgt-img,
.plans .plan input[type="checkbox"]:checked + .plan-content .lgt-img {
  border: 2px solid #000;
}

.custom-radio-btn {
  border: 1px solid #bbb9b9;
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  min-width: 300px;
}
.call-details {
  margin: 0px;
  padding: 0px;
}
.dynamic-body:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: -1;
}
.thanks-section .btn-apply {
  background-color: #d8b34f;
  color: #fff;
}
.gold {
  color: #d8b34f;
}
.paymentlink{
  text-decoration: none;
}
.delivery_address .MuiFormHelperText-root.Mui-error {
  color: #ffff00;
}
.MuiOutlinedInput-root.MuiInputBase-root.Mui-error
  .MuiOutlinedInput-notchedOutline,
.MuiInputBase-root.MuiInput-root.Mui-error,
.MuiInputBase-root.MuiInput-root.Mui-error:after {
  border-color: #ffff00;
}

@media only screen and (max-width: 600px) {
  body {
    height: inherit;
  }
  .call-details {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  /* .header-logo img {
    width: 70px;
  } */
  .que-section {
    padding: 15px 0;
  }
  .justify-content-end {
    justify-content: center !important;
  }
  .call {
    padding: 15px 0 0 0;
  }
  .que-section p {
    text-align: center;
  }
  .pb-60 {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 991px) {
  .home_box {
    margin-top: 0px !important;
  }
  .stepper__wrapper {
    display: block;
  }
  .stepper_text {
    font-size: 12px;
    padding-left: 0px;
    width: 100%;
  }
  .stepper__item {
    border-bottom: 1px solid #ffffff;
  }
  .stepper__item .stepper__link--disabled {
    border-bottom: 1px solid #367f84;
  }

  .stepper__item:last-child {
    border-bottom: 0px;
  }

  .stepper__link {
    padding: 15px 0 10px 15px;
  }
  .stepper {
    display: block;
  }
  .stepper__wrapper {
    margin-top: 110px;
    padding: 0px 20px;
  }
  .stepper__icon {
    width: auto;
  }
  .stepper__icon {
    margin-right: 10px;
  }
  .stepper__item:first-child .stepper__link {
    padding-left: 15px;
  }
  .logout-btn {
    margin-right: 20px;
  }
  .plans {
    margin: 0 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .plans .plan {
    width: 100%;
  }

  .plan.complete-plan {
    margin-top: 20px;
  }

  .plans .plan .plan-content .plan-details {
    width: 70%;
    display: inline-block;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
  .package-main{
    flex-direction: column-reverse;
    display: flex;
  }
  .pb-60 {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .cwlo-btn-pt {
    display: block;
  }
  .plans .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
  .pb-60 {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 540px) {
  .stepper__icon {
    width: auto;
  }

  .stepper__item:first-child .stepper__link {
    padding-left: 14px;
  }
  .homenavbutton {
    margin: 5px !important;
    font-size: 10px !important;
    padding: 8px 20px !important;
  }
  .stepper__wrapper {
    display: block;
  }
  .stepper_text {
    font-size: 14px;
    padding-left: 0px;
    width: 100%;
  }
  .stepper__item {
    border-bottom: 1px solid #ffffff;
  }

  .stepper__item:last-child {
    border-bottom: 0px;
  }

  .stepper__link {
    padding: 15px 0 10px 15px;
  }
  .stepper {
    display: block;
  }
  .stepper__wrapper {
    margin-top: 110px;
    padding: 0px 20px;
  }

  .plans .plan .plan-content img {
    height: 56px;
    width: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 20px;
    right: 10px;
  }

  .plans .plan .plan-content .plan-details {
    width: 100%;
  }
  .pb-60 {
    padding-bottom: 60px;
  }
}

/* inspiration */
.inspiration {
  font-size: 12px;
  margin-top: 50px;
  position: absolute;
  bottom: 10px;
  font-weight: 300;
}
.this-area h2 {
  font-size: 24px;
  color: #fff;
  padding-bottom: 20px;
}

.this-area p {
  font-size: 12px;
  color: #fff;
}

.inspiration a {
  color: #666;
}

@media screen and (max-width: 767px) {
  /* inspiration */
  .inspiration {
    display: none;
  }
}

/* Responsive Css */
@media (max-width: 1440px) {
}

@media (max-width: 1199px) {
  .postque .postque-bg {
    padding: 120px 30px 0px;
  }
  .postque .list-que ul {
    padding: 30px 40px 0 40px;
  }
  .ques-bg {
    padding: 150px 30px 20px 30px;
  }
  .que-bg {
    background: #fff;
    min-width: 100%;
    padding: 15px 40px;
    margin: 0 auto;
    min-height: 250px;
  }
  .que-data-cp {
    padding-bottom: 90px;
  }
  .stepper__icon {
    width: auto;
  }

  .stepper__item:first-child .stepper__link {
    padding-left: 14px;
  }
  .homenavbutton {
    margin: 5px !important;
    font-size: 10px !important;
    padding: 8px 20px !important;
  }
  .stepper__wrapper {
    display: block;
  }
  .stepper_text {
    font-size: 14px;
    padding-left: 0px;
    width: 100%;
  }
  .stepper__item {
    border-bottom: 1px solid #ffffff;
  }

  .stepper__item:last-child {
    border-bottom: 0px;
  }

  .stepper__link {
    padding: 15px 0 10px 15px;
  }
  .stepper {
    display: block;
  }
  .stepper__wrapper {
    margin-top: 110px;
    padding: 0px 20px;
  }
  .stepper {
    height: auto;
    overflow: hidden;
  }

  .stepper__item:last-child .stepper__link {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .stepper__item:first-child .stepper__link {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

@media (max-width: 991px) {
  .header-logo img {
    height: 30px;
  }
  .payment-method h3,
  .order-section h3 {
    font-size: 24px;
  }
  .pay-main-tital {
    font-size: 26px;
  }
  .total-label {
    font-size: 16px;
  }
  .total-price {
    font-size: 16px;
    width: 151px;
    text-align: right;
  }

  .plans {
    width: 90%;
    margin: 0px 0 10px 0;
  }
  .que-option .number-input.category-pkg-qty {
    width: 10%;
    display: flex;
    flex-direction: column;
    margin: 5px 0px 60px 0px;
  }
}

@media (min-width: 768px) {
  .stepper {
    height: auto;
    overflow: hidden;
  }

  .stepper__item:last-child .stepper__link {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .stepper__item:first-child .stepper__link {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

@media (max-width: 767px) {
  .reviewsection .reviewsection_ans {
    word-break: break-all;
  }
  .discount .btn-apply {
    display: block;
    margin: 15px auto;
  }
  .scrollable-section {
    margin-top: 50px;
  }
  .dynamic-body {
    padding-bottom: 50px !important;
  }

  .stepper {
    height: auto;
    overflow: hidden;
  }

  .stepper__item:last-child .stepper__link {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .stepper__item:first-child .stepper__link {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .custom-radio-btn {
    border: 1px solid #bbb9b9;
    min-width: 240px;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
  }
  .blog-header .header-logo img {
    width: 90px;
  }

  .blog-header .lgt-img {
    width: 32px;
    height: 32px;
  }
  .blog-header .back img {
    margin-left: 20px;
  }
  .blog-header .back {
    text-align: left;
    font-size: 10px;
  }
  .back {
    text-align: center;
    margin: 0 auto;
    font-size: 12px;
    justify-content: center;
  }
  .back-button {
    justify-content: center;
    font-size: 12px !important;
  }
  .que-top-pre p {
    font-size: 12px;
  }
  .postque .list-que {
    padding: 10px 0 0;
    overflow-y: auto;
  }
  .postque .list-que ul li span.shadow {
    display: block;
    padding: 15px 15px;
    font-size: 16px;
  }
  .bgImg-0-2-1 {
    width: 100%;
    height: 100vh !important;
  }
  .bgImg-0-2-5 {
    width: 100%;
    height: 100vh !important;
  }
  /* .back svg {
    width: 12px;
    height: 12px;
  } */
  .main-title {
    background: #ee6c4d;
    color: #fff;
    display: block;
    padding: 8px 10px;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
    border-radius: 5px;
    margin-bottom: 25px;
  }

  .postque .postque-title {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    text-align: justify;
    margin-bottom: 15px;
    width: 100%;
    text-transform: capitalize;
  }
  .pay-main-tital {
    font-size: 24px;
    padding-top: 80px;
  }
  .btn-apply {
    padding: 8px 25px;
    margin-left: 0px;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
  }
  .payment-method input.custom-input,
  .discount input.custom-input {
    height: 40px;
  }
  .pay-sub-tital {
    font-size: 13px;
  }
  .order-section {
    padding: 20px;
    margin: 20px 0px;
  }
  .payment-method h3,
  .order-section h3 {
    font-size: 22px;
  }
  .form-control {
    font-size: 14px;
  }
  .postque .list-que ul {
    padding: 20px 10px 0 10px;
  }
  .list-group-item {
    font-size: 14px;
    padding: 10px;
  }
  .postque .list-que ul li {
    font-size: 14px;
  }
  .que-data-cp {
    padding-bottom: 0px;
  }
  .body-0-2-1 {
    height: 100%;
  }
}

@media (max-width: 575px) {
  .profile-img img {
    width: 70px;
    height: 70px;
    border: 2px solid #fff;
  }
  .main-body {
    padding-top: 50px;
  }
  .plans {
    width: 80%;
    margin-bottom: 15px;
  }
  .que-option .number-input.category-pkg-qty {
    width: 20%;
    display: flex;
    flex-direction: column;
    margin: 5px 0px 60px 0px;
  }
  .ques-bg {
    padding: 210px 30px 20px 30px;
  }
  .que-bg {
    background: #fff;
    min-width: 60%;
    padding: 15px 20px;
  }
  body {
    overflow-y: auto;
  }
  .scroll-md {
    height: 86vh;
  }
  .postque .list-que:after {
    height: auto;
  }
  .postque .list-que {
    height: auto;
    width: 100%;
  }
  .user-toggle {
    display: none;
  }
  .postque .postque-bg {
    padding: 40px 30px 0px;
  }
  .slide-section ul {
    display: block;
  }
  .slide-section ul li {
    padding: 3px 0px;
    display: grid;
  }
  .back {
    text-align: center;
    /* margin: 0px 0px 0px 15px; */
  }
  .pb-60 {
    padding-bottom: 60px;
  }
}
