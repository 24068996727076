.main-body {
  height: 100%;
  padding-top: 60px;
  /* padding-bottom: 65px; */
  overflow-y: auto;
}
.theme-bg-color {
  background: #526574;
  padding: 3%;
  padding-bottom: 50px;
}
.scroll-md {
  max-width: 100%;
  height: 91vh;
  overflow-y: auto;
}
.scroll-md::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.scroll-md::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.scroll-md::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #808080;
}

.cm-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 5px 10px;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}
.ProfileForm form .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.ProfileForm form .MuiOutlinedInput-notchedOutline,
.ProfileForm
  form
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.ProfileForm form .react-tel-input .form-control:hover,
.ProfileForm form .react-tel-input .form-control:focus,
.ChangePassword
  form
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.ChangePassword form .MuiOutlinedInput-notchedOutline,
.ChangePassword
  form
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.react-tel-input .form-control:focus {
  border-color: #fff;
}
.que-data .MuiFormLabel-root,
.que-data .MuiFormLabel-root.Mui-focused {
  color: #fff;
}
.que-data .MuiInput-underline:hover:not(.Mui-disabled):before,
.que-data .MuiInput-underline:after {
  border-bottom: 2px solid #fff;
}

.postque .MuiFormLabel-root,
.postque .MuiFormLabel-root.Mui-focused {
  color: #fff;
}

.postque .MuiInputAdornment-root {
  color: #fff;
}

.postque .MuiInput-root {
  color: #fff;
}

.postque .MuiInput-underline:hover:not(.Mui-disabled):before,
.postque .MuiInput-underline:before,
.postque .MuiInput-underline:after {
  border-bottom: 2px solid #fff;
}

.postque .react-tel-input .form-control,
.postque .react-tel-input .form-control:focus {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-color: #fff !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.postque .react-tel-input .selected-flag .arrow {
  border-top: 4px solid #fff;
}
.postque .react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid #ccc;
}

.postque .MuiSelect-icon {
  color: #ccc;
}

.ProfileForm form .react-tel-input .selected-flag:focus:before,
.ProfileForm form .react-tel-input .selected-flag.open:before {
  border-color: #fff;
  box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 25%);
}
.ProfileForm form .react-tel-input .selected-flag .arrow.up {
  border-bottom: 4px solid #fff;
  border-top: none;
}
.ProfileForm form .react-tel-input .selected-flag:focus .arrow {
  border-top: 5px solid #fff;
}
.ProfileForm form .MuiInputBase-root,
.ProfileForm form .MuiFormLabel-root,
.ChangePassword form .MuiInputBase-root,
.ChangePassword form .MuiFormLabel-root,
.que-data form .MuiInputBase-root,
.que-data form .MuiFormLabel-root,
.ChangePassword form .MuiIconButton-root {
  color: #fff;
}
.ProfileForm form .react-tel-input .form-control {
  color: #fff !important;
}
.ProfileForm
  form
  .MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline,
.ChangePassword
  form
  .MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline,
.que-data
  form
  .MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #ffff00;
}
.ProfileForm form .MuiFormHelperText-root.Mui-error,
.ChangePassword form .MuiFormHelperText-root.Mui-error,
.que-data form .MuiFormHelperText-root.Mui-error {
  color: #ffff00;
}
.profile-title {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  margin-bottom: 25px;
}
.deleteIcon {
  color: yellow;
  opacity: unset;
  float: left;
  padding: 0 0 0 15px;
}
.profile-img .fupload {
  border-radius: 50%;
  background: #f8a934 !important;
  border-color: #f8a934 !important;
}
.profile-img .fileuploadmain {
  display: flex;
  width: 100%;
  color: #fff;
  margin-bottom: 5%;
}
.d-block.valid-feedback {
  color: #ffc677;
}
.profile-img {
  position: relative;
}

.fileuploadmain {
  position: absolute;
  right: -70px;
  bottom: -40px;
}
.box .active {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.input-file {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-width: 1px;
  border-radius: 5px;
  border-style: dashed;
  background-color: #9c9c9c50;
  color: #ffffffa8;
  outline: none;
  transition: border 0.24s ease-in-out;
  padding: 10px;
}
.dragStart {
  color: #0077ffa8;
}
.dragFailed {
  color: #ff0000a8;
}
.input-file-preview {
  margin: 10px;
}
.input-file-preview img {
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  border: 1px dotted #fff;
  border-radius: 5px;
  object-fit: contain;
  margin-right: 20px;
}
.rating input[type="radio"] + label {
  padding: 0;
}
.rating input[type="radio"] + label::before {
  content: unset;
}
.rating input[type="radio"] + label::after {
  content: unset;
}

.forgot_password input::placeholder,
.forgot_password .MuiFormLabel-root,
.forgot_password .MuiFormLabel-root.Mui-focused input::placeholder,
.forgot_password .MuiFormLabel-root.Mui-focused input:focus,
.forgot_password .MuiFormLabel-root.Mui-focused,
.forgot_password
  .MuiOutlinedInput-root.Mui-focused
  .Register_form
  input::placeholder,
.Login_form input::placeholder,
.Login_form .MuiFormLabel-root,
.Login_form .MuiFormLabel-root.Mui-focused input::placeholder,
.Login_form .MuiFormLabel-root.Mui-focused input:focus,
.Login_form .MuiFormLabel-root.Mui-focused,
.Login_form
  .MuiOutlinedInput-root.Mui-focused
  .Register_form
  input::placeholder,
.Register_form .MuiFormLabel-root,
.Register_form .MuiFormLabel-root.Mui-focused input::placeholder,
.Register_form .MuiFormLabel-root.Mui-focused input:focus,
.Register_form .MuiFormLabel-root.Mui-focused,
.Register_form .MuiOutlinedInput-root.Mui-focused {
  /* color: #808080 !important; */
  color: -internal-dark(black);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.ProfileForm form input::placeholder,
.ChangePassword form input::placeholder {
  color: #fff !important;
}
.ans-fill select option,
.ans-fill select {
  text-transform: capitalize !important;
}
.postque .postque-bg .que-data .MuiAutocomplete-root .MuiAutocomplete-input {
  color: #fff !important;
}
.postque input::placeholder {
  color: #ccc !important;
}
.postque .MuiSvgIcon-root {
  color: #ccc !important;
}
.preque-txt {
  width: 100%;
}
p.MuiFormHelperText-root.Mui-error {
  /* padding: 0px 12px; */
  text-align: left;
}
.spinner {
  width: 100px;
  height: auto;
}
.Register_form .MuiFormLabel-root.MuiInputLabel-root.Mui-error,
.Register_form .MuiFormHelperText-root.Mui-error,
.Register_form
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  color: #f54e47;
  border-color: #f54e47;
}

.Register_form .react-tel-input .special-label {
  display: block;
}
.Register_form .btn-color {
  color: #fff;
  background-color: #D8B34F;
    border: 1px solid #D8B34F;
}
.Register_form .btn-color:hover{
  background-color: #CE6632;
  border: 1px solid #CE6632;
}
.Register_form .btn-color:disabled {
  background-color: #d5d5dd;
  color: #000;
}
.postque .react-tel-input .country-list {
  margin: 0px 0 10px -1px;
}

.postque .selected-flag.open::before {
  border-color: #80bdff00;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

.postque .react-tel-input .form-control {
  background: #ffffff00;
}

.question-img {
  position: relative;
  width: 100;
  height: 100;
}

.question-img:hover .question-dellink {
  display: block !important;
}

.question-dellink {
  display: none;
  position: absolute !important;
  right: 0;
  top: 0;
}

.question-img .question-dellink svg {
  color: #f54e47 !important;
}

.Login_form .MuiButton-containedPrimary {
  background-color: #D8B34F;
}

.Login_form .MuiButton-containedPrimary:hover {
  background-color: #CE6632;
}
.payment-method .MuiFormHelperText-root.Mui-error {
  color: #ffff00;
}
.discount .invalid-feedback {
  color: #ffff00;
}
.btn-back{
  color: #D8B34F;
  text-decoration: underline;
}
.btn-back:hover{
  color: #D8B34F;
  text-decoration: underline;
}
.discount .row{
  align-items: center;
}
.ques-desc{
  color:#fff;
  text-align: center;
  align-items: center;
}
.ProfileForm .react-tel-input .selected-flag .arrow{
  border-top: 4px solid #fff;
}
.theme-bg-color .btn-color {
  color: #fff;
  background-color: #D8B34F;
    border: 1px solid #D8B34F;
}
.theme-bg-color .btn-color:hover {
  color: #fff;
  background-color: #CE6632;
  border: 1px solid #CE6632;
}
.theme-bg-color .btn-color:disabled {
  background-color: #d5d5dd;
  border: 1px solid #d5d5dd;
  color: #000;
}
@media (max-width: 575px) {
  .scroll-md {
    overflow-x: hidden;
  }
  .theme-bg-color {
    padding: 5%;
    padding-top: 10%;
  }
  .cm-footer {
    flex-direction: column;
    position: fixed;
    bottom: 0;
  }
}
